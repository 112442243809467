import React, { Component, useState } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import moment from 'moment';
import {
    NavLink,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Alert
} from 'reactstrap';
import _ from 'lodash'
import LoadingBar from 'react-top-loading-bar';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import { convertNumberToQr, convertQrToNumber, formatDate } from '../../lib/helpers';

class ListQRCode extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.deleteQRCode = this.deleteQRCode.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        this.state = {
            menuCode: constants.MENU_LIST_QRCODE,
            scope: [],
            columns: [
                {
                    name: 'Id',
                    selector: 'id',
                    sortable: true
                },
                {
                    name: 'Id Base36',
                    selector: 'idBase36',
                    sortable: true
                },
                {
                    name: 'PIN',
                    selector: 'pin',
                    sortable: true
                },
                {
                    name: 'Token',
                    selector: 'token',
                    sortable: true
                },
                {
                    name: 'Manufacturer',
                    selector: 'manufacturer_name',
                    sortable: true
                },
                {
                    name: 'QRCode',
                    selector: 'qrcode',
                    sortable: true,
                    cell: qrcode => qrcode.qrcode ? <img src={qrcode.qrcode} alt={qrcode.token} /> : '-',
                },
                {
                    name: 'Action',
                    selector: 'action',
                    sortable: false,
                    cell: qrcode =>
                        <div>
                            {
                                this.state.scope.includes('read') ?
                                    <>
                                        <button type="button" title="Detail Scan" className="btn btn-sm btn-outline-link" onClick={this.linkDetail.bind(this, qrcode.id)}>
                                            <li className="fa fa-eye"></li>
                                        </button>
                                        <button type="button" title="Location Scan" className="btn btn-sm btn-outline-link" onClick={this.openLocationModal.bind(this, qrcode.id, qrcode.idBase36)}>
                                            <li className="fa fa-compass"></li>
                                        </button>
                                    </>
                                    : null
                            }

                            {
                                this.state.scope.includes('update') ?
                                    <>
                                        <button type="button" title="Reset Redeem" className="btn btn-sm btn-outline-link" disabled={qrcode.is_redeemed ? false : true} onClick={this.openResetRedeemConfirmationModal.bind(this, qrcode.id)}>
                                            <li className="fa fa-refresh"></li>
                                        </button>
                                        <button type="button" title="Reset Scan" className="btn btn-sm btn-outline-link" onClick={this.openResetConfirmationModal.bind(this, qrcode.id)}>
                                            <li className="fa fa-rotate-left"></li>
                                        </button>
                                    </>
                                    : null
                            }
                            {
                                this.state.scope.includes('delete') ?
                                    <button type="button" className="btn btn-sm btn-danger" onClick={this.toggle.bind(this, qrcode.id)}>
                                        <li className="fa fa-trash"></li>
                                    </button>
                                    : null
                            }
                        </div>
                }
            ],
            data: [],
            isModalDeleteOpen: false,
            isModalResetOpen: false,
            isModalResetRedeemOpen: false,
            idBase36ModalPointer: null,
            scanCountPointer: null,
            locationsPerQRIDData: [],
            alertSuccess: false,
            deleteQRCodeId: "",
            resetQRCodeId: "",
            resetRedeemQRCodeId: "",
            paginationPerPage: 100,
            paginationTotalRows: 0,
            paginationPage: 1,
            from: '',
            to: '',
            search: "",
            loadingData: true,
            disableSearchQRCode: false,
            countRangeExport: 0
        };
    }

    componentDidMount() {
        this.checkAccess();
        this.getLoginData();
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    getLoginData() {
        var loginData = localStorage.getItem('loginData');
        var parseLoginData = JSON.parse(loginData)
        if (parseLoginData.data.username && parseLoginData.data.username === 'operational') {
            this.props.history.push("/token/add");
        } else {
            this.getListQRCode();
        }
    }

    getListQRCode() {
        this.setState({
            loadingData: true
        })
        API.get('token', {
            params: {
                size: this.state.paginationPerPage,
                page: this.state.paginationPage,
                search: this.state.search ?? undefined
            },
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
            .then(res => {
                console.log("List QRCode", res.data);
                this.setState({
                    data: res.data.item,
                    backupData: res.data.item,
                    paginationTotalRows: res.data.totalItem,
                    loadingData: false
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    linkEditValue(id) {
        this.props.history.push("/qrcode/edit/" + id);
    }

    toggle(id) {
        console.log("Id delete", typeof id)
        this.setState({
            isModalDeleteOpen: !this.state.isModalDeleteOpen,
        })

        if (typeof id == "number") {
            this.setState({
                deleteQRCodeId: id
            })
        }
    }

    openResetConfirmationModal(id) {
        // alert(id)
        this.setState({
            isModalResetOpen: !this.state.isModalResetOpen,
        })

        if (typeof id == "number") {
            this.setState({
                resetQRCodeId: id
            })
        }
    }

    openResetRedeemConfirmationModal(id) {
        this.setState({
            isModalResetRedeemOpen: !this.state.isModalResetRedeemOpen,
            resetRedeemQRCodeId: id
        })
    }

    resetRedeem(id) {
        API.get('product/redeemFalse/' + this.state.resetRedeemQRCodeId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                console.log(res)
                if (res.data.status) {
                    alert(res.data.message)
                    this.getListQRCode();
                    this.setState({
                        isModalResetRedeemOpen: !this.state.isModalResetRedeemOpen
                    })
                } else {
                    alert(res.data.message)
                }
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    openLocationModal(id, idBase36) {
        // alert(idBase36)
        this.setState({
            isModalLocationOpen: !this.state.isModalLocationOpen,
            idBase36ModalPointer: idBase36
        })
        API.get('location/listByQrCodeId/' + id, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                console.log(JSON.stringify(res.data))
                this.setState({
                    scanCountPointer: res.data.scan_count,
                    locationsPerQRIDData: res.data.locations
                })
                // alert(res.data.scan_count)
            })
    }

    deleteQRCode() {
        API.delete('qrcode/' + this.state.deleteQRCodeId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                console.log("QRCode deleted");
                this.getListQRCode();
                this.toggle();
                this.toggleAlertSuccess();
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    resetScan() {
        API.get('qrcode/reset/' + this.state.resetQRCodeId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(res => {
                // alert(res)
                if (res.data.success) {
                    console.log("QRCode resetted");
                    alert('Scan Count Has Been Resetted')
                    this.getListQRCode();
                    this.setState({
                        isModalResetOpen: !this.state.isModalResetOpen
                    })
                } else {
                    alert('Something went wrong. Please try again')
                }
                // this.toggle();
                // this.toggleAlertSuccess();
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    toggleAlertSuccess() {
        this.setState({
            alertSuccess: !this.state.alertSuccess
        })

        setTimeout(() => {
            this.setState({
                alertSuccess: !this.state.alertSuccess
            })
        }, 2000)
    }

    linkDetail(id) {
        this.props.history.push("/qrcode/" + id);
    }

    changePage(page) {

        this.setState({
            paginationPage: page
        }, () => {
            this.getListQRCode();
        })
    }

    changeRowsPerPage(rowsPerPage) {
        this.setState({
            paginationPerPage: rowsPerPage
        }, () => {
            this.getListQRCode();
        })
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value });
        console.log("data ", name, value)
        console.log("from ", this.state.from)

        if ((name === 'from' || name === 'to') && this.state.qty > 0 && this.state.from !== '') {
            console.log("qty ", this.state.qty)
            let from = convertQrToNumber(name == 'from' ? value : this.state.from);
            let to = convertQrToNumber(name == 'to' ? value : this.state.to);
            let countRangeExport = to - from + 1;
            if (this.state.from !== '') {
                this.setState({
                    countRangeExport: countRangeExport
                })
            }
        } else if (name === 'qty') {
            console.log("event.target.value ", event.target.value)
            let from = convertQrToNumber(this.state.from);
            let to = from + parseInt(value);
            if (this.state.from === '' && event.target.value > 0) {

                to = convertQrToNumber(name == 'to' ? value : this.state.to);
                from = (to - event.target.value)+1;
                console.log("masuk sini", from, to, this.state.countRangeExport, event.target.value)
                this.setState({
                    to: convertNumberToQr(to),
                    from: convertNumberToQr(from),
                    countRangeExport: parseInt(value)
                })
            } else {
                if (event.target.value > 0) {
                    this.setState({
                        to: convertNumberToQr(to),
                        countRangeExport: parseInt(value)
                    })
                } else {
                    this.setState({
                        countRangeExport: parseInt(value)
                    })
                }
            }
        }

    }

    handleSearch(event) {
        console.log("search", event.target.value)
        this.setState({ [event.target.name]: event.target.value.toUpperCase() });
    }

    async printQRFromTo() {
        this.LoadingBar.continuousStart()
        let listQR = [];
        let { from, to } = this.state;
        from = parseInt(from, 36);
        to = parseInt(to, 36);

        for (var i = from; i <= to; i++) {
            listQR.push(i);
        }
        console.log("List qr", listQR)

        let body = {
            listQR: listQR
        }

        API.post('/print/qr', body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(response => {
                this.LoadingBar.complete();

                setTimeout(() => {
                    window.location.href = "/";
                }, 500);
            })
            .catch(error => {
                console.log("Error", error);
            });
    }

    printCSVFromTo() {
        this.LoadingBar.continuousStart()
        let listQR = [];
        let { from, to } = this.state;
        from = parseInt(from, 36);
        to = parseInt(to, 36);

        for (var i = from; i <= to; i++) {
            listQR.push(i);
        }
        console.log("List qr", listQR)

        let body = {
            listQR: listQR
        }

        API.post('/qrcode/export-csv', body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(response => {
                this.LoadingBar.complete()

                setTimeout(() => {
                    window.open(response.data.linkCSV, "_blank")
                }, 500);
            })
            .catch(error => {
                console.log("Error", error);
            });
    }

    searchQRCode(e) {
        this.setState({
            loadingData: true
        })
        e.preventDefault();
        API.get('token', {
            params: {
                size: this.state.paginationPerPage,
                page: 1,
                search: this.state.search
            },
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
            .then(res => {
                console.log("res", res);
                this.setState({
                    data: res.data.item,
                    backupData: res.data.item,
                    paginationTotalRows: res.data.totalItem,
                    loadingData: false
                })
            })
            .catch(err => {
                console.log("Error", err)
                this.setState({
                    disableSearchQRCode: true,
                    loadingData: false
                })
                alert("Something went wrong. Please make sure add product first before search qrcode or try again on a few seconds.")
                setTimeout(() => {
                    this.setState({
                        disableSearchQRCode: false,
                        loadingData: true
                    })
                }, 5000)
            })
    }

    render() {

        console.log(this.state.scope)

        return (
            <div className="content">
                <LoadingBar onRef={ref => (this.LoadingBar = ref)} />
                <div className="animated fadeIn">
                    <div className="row">

                        <div className="col-md-12">
                            <Alert
                                color="success"
                                isOpen={this.state.alertSuccess}
                            >
                                QRCode deleted successfully
                            </Alert>
                            <div className="card">
                                <div className="card-header">
                                    <div className='mb-3'>
                                        <strong className="card-title">Data Table</strong>
                                    </div>
                                    {
                                        this.state.scope.includes('create') ?
                                            <NavLink href="/qrcode/generate" className="float-right d-none">
                                                <button type="button" className="btn btn-info">Generate QR Code</button>
                                            </NavLink>
                                            : null
                                    }
                                    {
                                        this.state.scope.includes('create') ?
                                            <NavLink>
                                                <div className="form-group row">
                                                    <div className="col-md-3 col-4">
                                                        <label for="From" class="col-form-label">From</label>
                                                        <input
                                                            id="from" name="from" type="text"
                                                            placeholder="Enter Start QRCode"
                                                            className="form-control valid"
                                                            data-val="true"
                                                            data-val-required="from"
                                                            autocomplete="from" aria-required="true"
                                                            aria-invalid="false" aria-describedby="from"
                                                            value={this.state.from}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 col-4">
                                                        <label for="From" class="col-form-label">To</label>
                                                        <input
                                                            id="to" name="to" type="text"
                                                            placeholder='Enter End QRCode'
                                                            className="form-control valid"
                                                            data-val="true"
                                                            data-val-required="Please enter the ProductID"
                                                            autocomplete="to" aria-required="true"
                                                            aria-invalid="false" aria-describedby="to"
                                                            value={this.state.to}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className="col-md-2 col-2">
                                                        <label for="From" class="col-form-label">Qty</label>
                                                        <div class="input-group">
                                                            <input
                                                                name="qty"
                                                                type="number"
                                                                placeholder="Please enter range first"
                                                                className="form-control valid"
                                                                value={this.state.countRangeExport}
                                                                onChange={this.handleChange}
                                                            />
                                                            <span class="input-group-text">QR Code</span>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-2 col-1" style={{ display:'flex', justifyContent:'space-around' }}>
                                                        <button type="button" onClick={()=>this.printQRFromTo()} className="btn btn-danger button-bottom-left-div w-100">Export Qr</button>
                                                    </div> */}
                                                    <div className="col-md-2 col-1" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <button type="button" onClick={() => this.printCSVFromTo()} className="btn btn-danger button-bottom-left-div" style={{ width: 'calc(100% - 1em)' }}>Export CSV</button>
                                                    </div>
                                                    <p className='m-0 mt-1 px-3 color-grey fs-13'>*QRCode Range cannot be greater than 2.000.000 data</p>
                                                </div>
                                            </NavLink>
                                            : null
                                    }

                                    <div className='mb-3'>
                                        <form onSubmit={(e) => this.searchQRCode(e)}>
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <input
                                                        id="search" name="search" type="text"
                                                        className="form-control valid"
                                                        data-val="true"
                                                        data-val-required="search"
                                                        autocomplete="search" aria-required="true"
                                                        aria-invalid="false" aria-describedby="search"
                                                        placeholder="Search by Base36 Id..."
                                                        value={this.state.search}
                                                        disabled={this.state.disableSearchQRCode}
                                                        // style={{ textTransform:'uppercase' }}
                                                        onChange={this.handleSearch}
                                                    />
                                                    <p className='m-0 mt-1 px-3 color-grey fs-13'>*you can use - or for searching multiple qrcode</p>
                                                </div>
                                                <div className='col-md-2 text-center'>
                                                    <button type="button" disabled={this.state.disableSearchQRCode} onClick={(e) => this.searchQRCode(e)} className="btn btn-info w-100">Search</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <DataTable
                                        title="List Generated QR Code"
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        hover
                                        paginationPerPage={this.state.paginationPerPage}
                                        paginationRowsPerPageOptions={[100, 200, 300, 400, 500, 1000]}
                                        pagesAmount={4}
                                        pagination={true}
                                        showPaginationTop={true}
                                        paginationServer={true}
                                        progressPending={this.state.loadingData}
                                        paginationTotalRows={this.state.paginationTotalRows}
                                        onChangePage={page => this.changePage(page)}
                                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                                    // onChangePage={}
                                    // onChangeRowsPerPage={}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.isModalDeleteOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Are you sure?</ModalHeader>
                    <ModalFooter>
                        <Button color="info" onClick={this.deleteQRCode}>Delete</Button>{' '}
                        <Button color="danger" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.isModalResetRedeemOpen} toggle={() => this.setState({ isModalResetRedeemOpen: !this.state.isModalResetRedeemOpen })}>
                    <ModalHeader toggle={() => this.setState({ isModalResetRedeemOpen: !this.state.isModalResetRedeemOpen })}>Are you sure?</ModalHeader>
                    <ModalFooter>
                        <Button color="info" onClick={() => this.resetRedeem()}>Reset Redeem</Button>{' '}
                        <Button color="danger" onClick={() => this.setState({ isModalResetRedeemOpen: !this.state.isModalResetRedeemOpen })}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.isModalResetOpen} toggle={() => this.setState({ isModalResetOpen: !this.state.isModalResetOpen })}>
                    <ModalHeader toggle={() => this.setState({ isModalResetOpen: !this.state.isModalResetOpen })}>Are you sure?</ModalHeader>
                    <ModalFooter>
                        <Button color="info" onClick={() => this.resetScan()}>Reset Scan</Button>{' '}
                        <Button color="danger" onClick={() => this.setState({ isModalResetOpen: !this.state.isModalResetOpen })}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal className='modal-list-location' isOpen={this.state.isModalLocationOpen} toggle={() => this.setState({ isModalLocationOpen: !this.state.isModalLocationOpen, scanCountPointer: 0, locationsPerQRIDData: [] })}>
                    <ModalHeader toggle={() => this.setState({ isModalLocationOpen: !this.state.isModalLocationOpen })}>List Location and Scan Count of Code {this.state.idBase36ModalPointer}</ModalHeader>
                    <ModalBody>
                        <p>Total Scan: {this.state.scanCountPointer}</p>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>Username/Email</th>
                                    <th>Fullname</th>
                                    <th>Phone</th>
                                    <th>Platform(OS)</th>
                                    <th>Gender</th>
                                    <th>Latitude</th>
                                    <th>Longitude</th>
                                    <th>Location</th>
                                    <th>Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.locationsPerQRIDData?.map((item, index) => {
                                        return (
                                            <tr>
                                                <td>{item.username ? item.username : '-'}</td>
                                                <td>{item.fullname ? item.fullname : '-'}</td>
                                                <td>{item.phone ? item.phone : '-'}</td>
                                                <td>{item.platform ? item.platform : '-'}</td>
                                                <td>{item.gender ? item.gender : '-'}</td>
                                                <td>{item.latitude}</td>
                                                <td>{item.longitude}</td>
                                                <td>{item.country !== null ? item.city_name + ', ' + item.province + ', ' + item.country : ''} {item.country === null ? item.city_name : ''}</td>
                                                <td>{formatDate(item.created_at)} WIB</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.setState({ isModalLocationOpen: !this.state.isModalLocationOpen, scanCountPointer: 0, locationsPerQRIDData: [] })}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default ListQRCode;